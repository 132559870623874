<template>
  <!-- Begin our services -->
  <section class="section services">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="wrapp-section-title section-title-center">
            <div class="section-subtitle">Areas what we serv</div>
            <h2 class="section-title">{{title}}</h2>
          </div>
        </div>
      </div>

      <services />

      <products />
      
      <div class="row">
        <div class="col-12">
          <div class="section-btns justify-content-center">
            <router-link to="services" class="btn btn-widht-ico btn-w240 ripple">
              <span>See All Services</span>
              <svg class="btn-widht-ico-right" viewBox="0 0 13 9">
                <use xlink:href="img/sprite.svg#arrow-right" />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End our serveces -->
</template>

<script>
export default {
  name: "Cloud",
  data() {
    return {
      title: "Cloud"
    };
  }
};
</script>

<style>
</style>